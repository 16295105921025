.DayPicker {
  width: auto !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.DayPicker > div > div {
  width: auto !important;
}

.DayPicker_transitionContainer {
  width: 100% !important;
}

.CalendarDay__selected,
.CalendarDay__selected:hover {
  background: #6713D2;
  border: 1px double #6713D2;
}

.CalendarMonthGrid__horizontal {
  width: 200% !important;
}

.CalendarMonthGrid_month__horizontal {
  width: 48% !important;
}

.CalendarMonth_table {
  width: 100% !important;
}

.DayPicker_weekHeader {
  width: 98% !important;
}

.CalendarMonth_caption {
  padding-bottom: 42px !important;
}

.DayPicker_weekHeader_ul {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.DayPickerKeyboardShortcuts_buttonReset {
  display: none;
}
